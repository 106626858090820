<template>
    <div>
        <section class="text-section">
            <div class="container">
                <h1>Политика конфиденциальности</h1>
                <h2>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ.</h2>
                <p>1.1. Веб-сервис «Байторг» (далее – Веб-сервис) – Сайт, расположенный в глобальной
                    компьютерной сети Интернет (далее – сеть Интернет) по адресу: {{baseUrl}}, на котором
                    Администрацией Веб-сервиса размещается информация (сведения) из открытых источников о
                    государственных и частных закупках в Республике Беларусь.</p>
                <p>1.2. Пользователь/Пользователи – идентифицированное или не идентифицированное физическое
                    лицо, индивидуальный предприниматель, представитель юридического лица, использующий
                    функционал Веб-сервиса (или) зарегистрировавшийся на Веб-сервисе в установленном порядке
                    путем прохождения процедуры регистрации и создания аккаунта, в отношении которого проводится
                    сбор и обработка личных данных.</p>
                <p>1.3. Личные информация Пользователя (личные данные Пользователя) – любая информация и
                    сведения, позволяющие идентифицировать физическое лицо.</p>
                <p>1.4. Администрация Веб-сервиса – Общество с ограниченной ответственностью «Байторг Бай»,
                    владелец Веб-сервиса, указанного в п. 1.1. настоящей Политики.</p>
                <p>1.5. Использование личных данных – сбор, запись, систематизация, накопление, хранение,
                    уточнение (обновление, изменение), извлечение, использование, передача (распространение,
                    предоставление, доступ), обезличивание, блокирование, удаление и уничтожение личных данных.</p>
                <p>1.6. Веб-сервис - Сайт в сети Интернет, на котором Администрацией Веб-сервиса размещается
                    информация (сведения) из открытых источников о государственных и частных закупках в
                    Республике Беларусь.</p>
                <p>1.7. Настоящая Политика является официальным документом Администрации Веб-сервиса, разработанным и
                    утвержденным в установленном порядке. Действующая редакция Политики является публичным
                    документом, который доступен любому пользователю сети Интернет по адресу:
                    {{baseUrl}}.</p>

                <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                <p>2.1. Настоящая Политика регулирует цели, порядок и условия использования Администрацией
                    Веб-Сервиса личной информации Пользователя.</p>
                <p>2.2. При прохождении процедуры регистрации и создании аккаунта Пользователь предоставляет
                    Администрации Веб-сервиса свои личные данные, включая имя Пользователя, адрес электронной почты и
                    пароль.</p>
                <p>2.3. Даже без входа в аккаунт Пользователь может сообщить Администрации Веб-сервиса некоторые
                    сведения о себе, например, адрес электронной почты, чтобы получать новости и рассылки от
                    Администрации Веб-сервиса.</p>
                <p>2.4. Администрация Веб-сервиса регистрирует информацию о приложениях, браузерах и устройствах,
                    которые использует Пользователь для доступа к функционалу Веб-сервиса.</p>
                <p>2.5. Помимо прочего Администрация Веб-сервиса собирает и обрабатывает уникальные идентификаторы, а
                    также такие данные, как тип и настройки браузера и устройства, операционная система, мобильная
                    сеть (включая название оператора и номер телефона) и номер версии приложения. Администрацией
                    Веб-сервиса также регистрируется информация о взаимодействии приложений, браузеров и устройств
                    Пользователя с функционалом Веб-сервиса, в том числе IP-адрес, отчеты о сбоях, сведения о
                    действиях в системе, дата и время, когда Пользователь посетил Веб-сервис, и URL, с которого
                    Пользователь на него перешел (URL перехода).</p>
                <p>2.6. Администрация Веб-сервиса собирает и обрабатывает информацию о том, какие действия
                    Пользователь выполняет на Веб-сервисе. Это обеспечивает работу различных полезных функций
                    Веб-сервиса, например, выбор рекомендуемых услуг (закупок). К такой информации относятся:</p>
                <ul>
                    <li>- запросы, по которым Пользователь выполнял поиск на Веб-сервисе;</li>
                    <li>- услуги, которые просматривал Пользователь;</li>
                    <li>- просмотры и взаимодействия с контентом и объявлениями;</li>
                    <li>- заказы;</li>
                    <li>- история просмотров страниц Веб-сервиса.</li>
                </ul>
                <p>2.7. Администрация может собирать и обрабатывать информацию о местоположении Пользователя, когда
                    Пользователь пользуется функционалом Веб-сервиса, позволяющим определить и показать маршрут до
                    интересующих Пользователя объектов.</p>
                <p>Администрация Веб-сервиса определяет местоположение Пользователя с определенной степенью точности,
                    учитывая следующие факторы:</p>
                <ul>
                    <li>- данные GPS;</li>
                    <li>- IP-адрес;</li>
                    <li>- данные с датчиков на устройстве, с которого используется доступ к Веб-сервису;</li>
                    <li>- сведения об объектах вокруг Пользователя, например, точках доступа Wi-Fi, антеннах сотовой
                        связи и устройствах с включенным модулем Bluetooth.
                    </li>
                    <li></li>
                </ul>
                <p>Типы данных о местоположении, которые собирает и обрабатывает Администрация Веб-сервиса, частично
                    зависят от настроек устройства и аккаунта Пользователя. Например, Пользователь может отключить
                    или включить на своем смартфоне и ином девайсе геолокацию.</p>
                <p>2.8. В некоторых случаях Администрация Веб-сервиса также собирает данные о Пользователе из
                    общедоступных источников. Администрация Веб-сервиса также может получать информацию о
                    Пользователе от доверенных лиц.</p>
                <p>Помимо прочего, к ним относятся партнеры по маркетингу, которые предоставляют Администрации
                    Веб-сервиса сведения о потенциальных клиентах, а также лица, которые консультируют Администрацию
                    Веб-сервиса по вопросам обеспечения безопасности и профилактики злоупотреблений. Кроме того,
                    рекламодатели делятся с Администрацией Веб-сервиса определенной информацией, которая необходима
                    для проведения маркетинговых исследований и показа объявлений.</p>
                <p>2.9. Для получения и хранения данных Администрация Веб-сервиса применяет различные технологии,
                    такие как файлы cookie и теги пикселей, локальные хранилища, например, интернет-хранилище в
                    браузере или кэш приложений, базы данных и журналы серверов.</p>
                <p>2.10. Настоящая Политика разработана и утверждена в соответствии с рядом законодательных актов, в
                    том числе с Регламентом (ЕС) 2016/679 Европейского Парламента и Европейского Совета от 27 Апреля
                    2016 года о защите физических лиц при обработке персональных данных и о свободном обращении таких
                    данных, а также об отмене Директивы 95/46/EC (Общий регламент по защите персональных данных).</p>

                <h2>3. УСЛОВИЯ И ЦЕЛИ СБОРА И ОБРАБОТКИ ЛИЧНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ.</h2>
                <p>3.1. Благодаря полученным данным Администрация Веб-сервиса имеет возможность поддерживать и
                    улучшать существующий функционал Веб-сервиса, создавать новый функционал, а также обеспечивать
                    безопасность Веб-сервиса и Пользователей.</p>
                <p>3.2. Администрация Веб-сервиса использует личные данные Пользователей для обеспечения работы
                    Веб-сервиса. Например, они помогают Администрации Веб-сервиса обрабатывать поисковые запросы
                    Пользователей.</p>
                <p>3.3. Пользовательская информация также необходима для обеспечения стабильной работы функционала
                    Веб-сервиса (например, она помогает отслеживать сбои) и для их оптимизации. В частности
                    Администрация Веб-сервиса может определять, в каких поисковых запросах чаще всего допускаются
                    ошибки. Это позволяет Администрация Веб-сервиса улучшить функцию проверки орфографии.</p>
                <p>3.4. Администрация Веб-сервиса использует собранные данные для того, чтобы настраивать функционал
                    Веб-сервиса в соответствии с потребностями Пользователей, предоставлять индивидуальные
                    рекомендации, специально подобранный контент и результаты поиска.</p>
                <p>3.5. Администрация Веб-сервиса не показывает Пользователям персонализированную рекламу, связанную
                    с деликатными категориями, такими как расовая и религиозная принадлежность, сексуальная
                    ориентация или состояние здоровья.</p>
                <p>3.6. Администрация Веб-сервиса не осуществляет обработку персональных данных, раскрывающих расовое
                    или этническое происхождение, политические взгляды, религиозные или философские убеждения, или
                    членство в профсоюзах, а также обработку генетических данных, биометрических данных с целью
                    однозначной идентификации физического лица, данных о здоровье или данных, касающиеся сексуальной
                    жизни или сексуальной ориентации физического лица.</p>
                <p>3.7. Администрация Веб-сервиса не передает рекламодателям личную информацию Пользователя, такую
                    как имя и адрес электронной почты, кроме случаев, когда Пользователь самостоятельно дал на это
                    согласие.</p>
                <p>3.8. Администрация Веб-сервиса использует личную информацию Пользователя, чтобы отслеживать, как
                    Пользователи взаимодействуют с функционалом Веб-сервиса. Так, Администрация Веб-сервиса
                    анализирует статистику посещений Веб-сервиса, чтобы оптимизировать его внутреннюю структуру. В
                    свою очередь, рекламодатели могут оценивать эффективность своих кампаний на основании информации
                    о том, на какие объявления Пользователи нажимают.</p>
                <p>3.9. Администрация Веб-сервиса использует личную информацию, такую как адрес электронной почты,
                    для коммуникации с Пользователем. К примеру, Администрация Веб-сервиса может отправлять
                    оповещения о подозрительных попытках входа в аккаунт Пользователя с новых устройств. Когда
                    Пользователь обращается к Администрации Веб-сервиса, сообщения Пользователя сохраняются, чтобы
                    Администрация Веб-сервиса могла решить проблему быстрее.</p>
                <p>3.10. Информация, которую собирает и обрабатывает Администрация Веб-сервиса, крайне важна для
                    обеспечения безопасности и надежности функционала Веб-сервиса. Располагая ей, Администрация
                    Веб-сервиса может своевременно выявлять и блокировать угрозы, чтобы не позволить злоумышленникам
                    нанести вред Администрации Веб-сервиса, Пользователям и общественным интересам.</p>
                <p>3.11. Для выполнения этих задач Администрация Веб-сервиса использует разные технологии обработки
                    данных. Автоматизированные системы анализируют контент Пользователей и специально подбирают для
                    Пользователей объявления и результаты поиска, а также адаптируют другие функции с учетом того,
                    как Пользователь пользуется функционалом Веб-сервиса. Кроме того, это позволяет вовремя пресекать
                    распространение спама, вредоносного ПО и нелегального контента, а также препятствовать прочей
                    противоправной деятельности.</p>
                <p>3.12. Если адрес электронной почты или иная идентификационная информация Пользователя уже известна
                    другим Пользователям, Администрация Веб-сервиса может сообщить им общедоступные данные аккаунта
                    Пользователя, включая имя и фотографию. Благодаря этому, например, Пользователям будет проще
                    определять, какие письма приходят им от других Пользователей.</p>
                <p>3.13. Если данные Пользователя требуются Администрации Веб-сервиса для выполнения действий, не
                    упомянутых в текущей Политике конфиденциальности, Администрация Веб-сервиса всегда запрашивает у
                    Пользователя предварительное согласие на их использование.</p>
                <p>3.14. Пользователь может управлять своими личными данными, сохраненными в аккаунте. Для этого
                    нужно выполнить вход в свой аккаунт и перейти в нужный сервис.</p>
                <p>3.15. Пользователь вправе отказаться от получения любого рода рассылок, в том числе рекламных,
                    воспользовавшись функцией «Отписаться» или посредством проставления отметки в соответствующей
                    графе в таких электронных письмах или обратившись к Администрации Веб-сервиса, направив
                    соответствующий запрос по электронной почте, указанной в п. 11.4. настоящей Политики.</p>
                <p>Пользователь имеет возможность просматривать и изменять свои данные, в том числе информацию о
                    себе.</p>
                <p>В разделе настроек аккаунта можно управлять данными, которые создаются, когда Пользователь
                    пользуется функционалом Веб-сервиса.</p>
                <p>3.16. В настройках аккаунта Пользователь может управлять своей контактной информацией – в нее
                    входит имя Пользователя, адрес электронной почты, номер телефона и т. д.</p>
                <p>3.17. Пользователь имеет право копировать свою информацию из аккаунта, в том числе если
                    Пользователь планирует использовать эти данные в стороннем сервисе.</p>
                <p>3.18. В соответствии с законодательством в некоторых ситуациях у Пользователя также есть право
                    потребовать от Администрации Веб-сервиса удалить контент с Веб-сервиса.</p>
                <p>3.19. Пользователь может удалить информацию, содержащую его личные данные, следующими способами:</p>
                <ul>
                    <li>- удалить определенные данные из аккаунта;</li>
                    <li>- полностью удалить свой аккаунт путем направления соответствующего запроса в адрес
                        Администрации Веб-сервиса по адресу электронной почты, указанному в п. 11.4. настоящей
                        Политики. Запрос должен быть составлен Пользователем таким образом, чтобы волеизъявление
                        пользователя удалить свой аккаунт было четким и однозначным.
                    </li>
                </ul>
                <p>При этом пользователь соглашается с тем, что весь или часть функционала Веб-сервиса, в том числе
                    услуги, которые оказываются Пользователю Администрацией Веб-сервиса, могут стать недоступными
                    Пользователю в результате таких действий.</p>
                <p>3.20. Если Пользователь пользуется функционалом Веб-сервиса, выполнив вход в аккаунт, например,
                    добавляет комментарии или отзывы, то рядом с опубликованной Пользователем информацией могут
                    показываться имя и фотография Пользователя.</p>
                <p>3.21. Администрация Веб-сервиса не раскрывает личную информацию Пользователей компаниям,
                    организациям и частным лицам, не связанным с Администрацией Веб-сервиса. Исключение составляют
                    ситуации, перечисленные ниже:</p>
                <ul>
                    <li>- Пользователь дал на это свое согласие.<br>Личная информация Пользователя может быть
                        передана юридическим или физическим лицам, не связанным с Администрацией Веб-сервиса, если
                        Пользователь дал согласие на это. Личная чувствительная информация Пользователя не передается
                        никому без явного согласия Пользователя.
                    </li>
                    <li>- Для обработки третьими сторонами по поручению Администрации Веб-сервиса.<br> Администрация
                        Веб-сервиса может предоставлять персональные данные аффилированным лицам Администрации
                        Веб-сервиса, иным доверенным компаниям и лицам для обработки от имени Администрации
                        Веб-сервиса. Такая обработка осуществляется в соответствии с инструкциями Администрации
                        Веб-сервиса, политикой конфиденциальности и другими применимыми требованиями
                        конфиденциальности и безопасности. В частности, сторонние компании могут осуществлять
                        поддержку Пользователей.
                    </li>
                    <li>- По требованию законодательства.</li>
                </ul>
                <p>Администрация Веб-сервиса может предоставить личную информацию Пользователя юридическим и
                    физическим лицам, не связанным с Администрацией Веб-сервиса, если Администрация Веб-сервиса
                    добросовестно полагает, что эти лица вправе получать, использовать, хранить или раскрывать эту
                    информацию на следующих основаниях:</p>
                <p>Они обеспечивают соблюдение требований законодательства, реализуют судебное решение или исполняют
                    в принудительном порядке запрос государственного учреждения;</p>
                <p>Они выявляют мошенничество, пресекают его или иным образом стремятся ему воспрепятствовать, а
                    также работают над устранением технических неполадок или проблем с безопасностью.</p>
                <p>Они защищают права, собственность или безопасность Администрации Веб-сервиса, Пользователей
                    Веб-сервиса или общественности в соответствии с требованиями законодательства и на основании
                    полномочий, предоставленных им законом.</p>
                <p>3.22. Администрация Веб-сервиса имеет право предоставлять личную информацию, не позволяющую
                    идентифицировать Пользователя, всем Пользователям и партнерам Администрации Веб-сервиса, таким
                    как издатели, рекламодатели, разработчики и правообладатели.</p>
                <p>3.23. Если Администрация Веб-сервиса будет вовлечена в сделки по слиянию, поглощению или продаже
                    активов, Администрация Веб-сервиса по-прежнему будет обеспечивать конфиденциальность всех
                    персональных данных. Администрация Веб-сервиса уведомляет всех заинтересованных Пользователей в
                    случае, если их личная информация будет передана другой организации или регулироваться иной
                    политикой конфиденциальности.</p>

                <h2>4. ПРИМЕНЕНИЕ ТЕХНОЛОГИЙ ОТСЛЕЖИВАНИЯ И ФАЙЛОВ COOKIE</h2>
                <p>4.1. Администрация Веб-сервиса использует файлы cookie и аналогичные технологии отслеживания для
                    мониторинга активности Пользователей на Веб-сервисе и хранения определенной информации.</p>
                <p>4.2. Под технологиями отслеживания подразумеваются маяки, теги и скрипты для сбора и отслеживания
                    информации, а также улучшения и анализа Веб-сервиса.</p>
                <p>4.3. Пользователь, получив соответствующее уведомление в своем браузере, может отказаться от
                    использования всех файлов cookie или указать когда они будут отправлены. В свою очередь, если
                    Пользователь не соглашается с использование файлов cookie и (или) отклоняет (пропускает)
                    соответствующее уведомление, он соглашается с тем, что некоторая часть функционала Веб-сервиса может
                    быть ему недоступна.</p>
                <p>4.4. Администрация Веб-сервиса использует только «сессионные» файлы cookie, которые удаляются сразу
                    же как только Пользователь закрывает свой браузер.</p>
                <p>4.5. «Сессионные» файлы cookie необходимы для предоставления Пользователю услуг, доступных через
                    Веб-сервис, а так же для того, чтобы Пользователь мог использовать некоторые из функций
                    Веб-сервиса. «Сессионные» файлы cookie помогают аутентифицировать Пользователей и предотвращать
                    мошенническое использование учетных записей Пользователей. Без указанных файлов cookie некоторая
                    часть функционала Веб-сервиса может быть недоступна Пользователю.</p>

                <h2>5. СРЕДСТВА ЗАЩИТЫ ИНФОРМАЦИИ.</h2>
                <p>5.1. На Веб-сервисе реализованы надежные средства защиты, обеспечивающие безопасность данных
                    Пользователя. При обслуживании Веб-сервиса Администрация Веб-сервиса получает информацию, которая
                    позволяет обнаруживать и автоматически устранять самые разные проблемы безопасности. При
                    необходимости Администрация Веб-сервиса сообщает Пользователю о тех или иных проблемах,
                    консультирует по вопросам их предотвращения.</p>
                <p>5.2. Администрация Веб-сервиса предпринимает все возможное для того, чтобы обезопасить Веб-сервис
                    и Пользователей от несанкционированных попыток доступа, изменения, раскрытия или уничтожения
                    хранящихся у Администрации Веб-сервиса данных. Помимо прочего, Администрация Веб-сервиса
                    принимает следующие меры:</p>
                <ul>
                    <li>- применение специальных средств защиты аккаунтов Пользователей;</li>
                    <li>- постоянное совершенствование способов сбора, хранения и обработки данных, включая
                        физические меры безопасности, для предотвращения несанкционированного доступа к системам
                        Администрации Веб-сервиса;
                    </li>
                    <li>- ограничение для сотрудников Администрации Веб-сервиса, подрядчиков и агентов доступа к
                        личной информации, а также наложение на них строгих договорных обязательств, за нарушение
                        которых предусмотрены санкции или увольнение.
                    </li>
                    <li>- назначение ответственного лица за организацию обработки персональных данных из числа
                        сотрудников Администрации Веб-сервиса;
                    </li>
                </ul>

                <h2>6. КОПИРОВАНИЕ И УДАЛЕНИЕ ИНФОРМАЦИИ.</h2>
                <p>6.1. Пользователь может копировать свою информацию из аккаунта, если Пользователю нужна ее копия или
                    Пользователь планирует использовать эти данные в стороннем сервисе.</p>
                <p>6.2. Пользователь может удалить с Веб-сервиса информацию о себе следующими способами:</p>
                <ul>
                    <li>- удалить определенные материалы из аккаунта;</li>
                    <li>- полностью удалить свой аккаунт путем направления соответствующего запроса в адрес
                        Администрации Веб-сервиса по адресу электронной почты, указанному в п. 11.4. настоящей
                        Политики. Запрос должен быть составлен Пользователем таким образом, чтобы волеизъявление
                        пользователя удалить свой аккаунт было четким и однозначным.<br>При этом пользователь
                        соглашается с тем, что весь или часть функционала Веб-сервиса, в том числе услуги, которые
                        оказываются Пользователю Администрацией Веб-сервиса могут стать недоступными Пользователю в
                        результате таких действий.
                    </li>
                </ul>

                <h2>7. ХРАНЕНИЕ ИНФОРМАЦИИ.</h2>
                <p>7.1. Администрация Веб-сервиса хранит собранные данные в течение некоторого времени. Длительность
                    хранения зависит от типа информации, а также от того, как Администрация Веб-сервиса ее
                    использует.</p>
                <p>7.2. Другие данные (например, данные о рекламе, хранящиеся в журналах сервера) автоматически
                    удаляются или обезличиваются по истечении определенного времени.</p>
                <p>7.3. Некоторые данные (например, информацию о том, как часто Пользователь использует Веб-сервис)
                    Администрация Веб-сервиса хранит до тех пор, пока Пользователь не удалит свой аккаунт.</p>
                <p>7.4. Некоторые данные хранятся Администрацией дольше, если это обусловлено законными коммерческими
                    или юридическими целями (например, для обеспечения безопасности, защиты от мошенничества и других
                    противоправных действий или для финансовой отчетности).</p>
                <p>7.5. Если Пользователь удаляет какие-либо данные, Администрация Веб-сервиса действует в
                    соответствии со специальными правилами. В результате данные Пользователя полностью исчезают с
                    серверов Администрации Веб-сервиса или сохраняются там лишь в обезличенной форме. Администрация
                    Веб-сервиса делает все необходимое, чтобы личные данные Пользователей на Веб-сервисе не были
                    удалены случайно или в результате противоправных действий. Поэтому информация может исчезнуть с
                    Веб-сервиса и систем резервного копирования не сразу после того, как Пользователь удалите ее.</p>

                <h2>8. СОБЛЮДЕНИЕ НОРМАТИВНЫХ ТРЕБОВАНИЙ И ВЗАИМОДЕЙСТВИЕ С РЕГУЛИРУЮЩИМИ ОРГАНАМИ</h2>
                <p>8.1. Администрация Веб-сервиса регулярно обновляет Политику конфиденциальности и обрабатывает
                    информацию Пользователей в соответствии с ней.</p>
                <p>8.2. Информация конкретного Пользователя может обрабатываться не в той стране, в которой он
                    проживает. Уровень защиты информации и законодательные нормы в этой сфере могут отличаться в
                    разных странах. Вне зависимости от того, где именно осуществляется обработка данных Пользователя,
                    Администрация Веб-сервиса использует одни и те же меры обеспечения их безопасности, описанные в
                    этой Политике конфиденциальности.</p>
                <p>8.3. Когда Администрация Веб-сервиса получает жалобу в письменном виде, Администрация Веб-сервиса
                    связывается с ее отправителем. Если Администрации Веб-сервиса не удается урегулировать претензию,
                    касающуюся использования личных данных, напрямую при взаимодействии с Пользователем,
                    Администрация Веб-сервиса передает ее на рассмотрение в государственные органы, у которых есть
                    соответствующие полномочия.</p>

                <h2>9. ПРИНЦИПЫ ОБРАБОТКИ ЛИЧНЫХ ДАННЫХ ДЕТЕЙ</h2>
                <p>9.1. Веб-сервис не предназначен для использования лицами младше 16 лет. Администрация Веб-сервиса
                    сознательно не осуществляет использование личных данных лиц младше 16 лет.</p>
                <p>9.2. Если родителям (опекунам, иным законным представителям) лица младше 16 лет станет известно,
                    что такое лицо предоставило Администрации Веб-сервиса свои личные данные, Администрация
                    Веб-сервиса просит уведомить её о таких фактах.</p>
                <p>9.3. Если Администрации Веб-сервиса станет известно, что Администрация Веб-Сервиса использовала
                    личные данные какого-либо лица в возрасте до 16 лет без наличия полученного в установленном
                    порядке согласия родителей (опекуна, иного законного представителя), Администрация Веб-сервиса
                    предпримет все необходимые действия для скорейшего удаления таких данных с Веб-сервиса.</p>
                <p>9.4. Администрация Веб-сервиса оставляет за собой право ограничивать использование личных данных
                    Пользователей в возрасте от 16 до 18 лет. В некоторых случаях такое ограничение означает, что
                    указанной категории Пользователей функционал Веб-сервиса будет доступен частично.</p>

                <h2>10. ОЗНАКОМЛЕНИЕ С НАСТОЯЩЕЙ ПОЛИТИКОЙ</h2>
                <p>10.1. Ознакомление и согласие с условиями и положениями настоящей Политики может происходить
                    следующими способами:</p>
                <p>10.2. Непосредственно в процессе регистрации Пользователя (создания аккаунта) на Веб-ресурсе путем
                    проставления соответствующей отметки в графе «Я согласен с условиями Политики конфиденциальности
                    и обработки персональных данных».</p>
                <p>10.3. Путем ознакомления Пользователя с актуальной редакцией Политики, которая размещена в сети
                    Интернет по адресу: {{baseUrl}}/{{privacyPolicyPath}} </p>

                <h2>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
                <p>11.1. Настоящая Политика конфиденциальности не распространяется на другие Интернет-ресурсы, кроме
                    Веб-сервиса.</p>
                <p>11.2. Настоящая Политика конфиденциальности не регулирует следующее:</p>
                <ul>
                    <li>- процедуры обработки данных в других компаниях и организациях, которые рекламируют
                        Веб-сервис;
                    </li>
                    <li>- услуги, предлагаемые другими компаниями или частными лицами, ссылки на которые показываются в
                        результатах поиска по Веб-сервису.
                    </li>
                </ul>
                <p>11.3. Администрация Веб-сервиса периодически вносит в Политику конфиденциальности изменения,
                    однако не намерена в будущем ограничивать права Пользователей, описанные в настоящей Политике,
                    без их явного согласия. Администрация Веб-сервиса всегда указывает дату последних изменений,
                    внесенных в Политику, и предоставляет доступ к ее прежним версиям. Если сделаны значительные
                    поправки, то Администрация Веб-сервиса объявляет о них дополнительно, а в некоторых случаях может
                    присылать уведомления по электронной почте.</p>
                <p>11.4. Связаться с Администрацией Веб-сервиса по любым вопросам настоящей Политики, в том числе по
                    вопросам изменения и удаления личных данных Пользователя, предоставления и отзыва согласия
                    Пользователя на обработку личных данных, отказа от получения новостных, маркетинговых и рекламных
                    рассылок, получения доступа к своим персональным данным возможно, направив соответствующий запрос
                    по электронной почте, указанной на странице Веб-сервиса в сети Интернет: {{baseUrl}}</p>
                <p>11.5. При несогласии с настоящей Политикой, а равно согласии с ней не в полном объеме,
                    Пользователю полностью или частично может быть недоступен функционал Веб-сервиса.</p>
            </div>
        </section>
        <footer class="footer">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-sm-4 col-lg-5">
                        <img src="../assets/img/logo.png" alt="" class="footer-logo" width="113" height="54">
                    </div>
                    <div class="col-sm-8 col-lg-7">
                        <div class="row">
                            <div class="offset-md-6 col-md-6">
                                <router-link
                                        :to="termsOfUsePath"
                                        exact
                                        class="footer-link"
                                >
                                    <span>Правила пользования</span>
                                    <ico name="icoOpenInNew" color="#79919C"></ico>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-xl-4 order-xl-2">
                        <div class="footer-contacts">
                            <div class="footer-contacts-item">
                                <p class="footer-contacts-item-title">Телефон</p>
                                <a class="footer-contacts-item-link" href="tel:+375447228833">+375447228833</a>
                            </div>
                            <div class="footer-contacts-item">
                                <p class="footer-contacts-item-title">Вопросы и предложения</p>
                                <a class="footer-contacts-item-link" href="mailto:office@bytorg.by">office@bytorg.by</a>
                            </div>
                            <div class="footer-contacts-item">
                                <p class="footer-contacts-item-title">Бухгалтерия</p>
                                <a class="footer-contacts-item-link" href="mailto:buh@bytorg.by">buh@bytorg.by</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 order-xl-1">
                        <p class="footer-copyright">© ООО “Байторг Бай”, 2024</p>
                        <p class="footer-copyright">Копирование материалов запрещено. При согласованном использовании
                            материалов сайта необходима ссылка на ресурс.</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
    import {homePath} from "@/router/home"
    import {termsOfUsePath} from "@/router/termsOfUse"
    import Configuration from "@/configuration"
    import {privacyPolicyPath} from "@/router/privacyPolicy"

    export default {
        name: "PrivacyPolicy",
        data() {
            return {}
        },
        computed: {
            baseUrl() {

                return Configuration.value('apiEndpoint')
            },
            privacyPolicyPath() {
                return privacyPolicyPath.substring(1)
            },
            termsOfUsePath() {
                return termsOfUsePath
            },
            homePath() {
                return homePath
            }
        },
    }
</script>
<style lang="less" scoped>
    @import '../assets/less/variables.less';
    @import '../assets/less/bootstrapGrid.less';
    @import '../assets/less/commonStyles.less';
    @import '../assets/less/homeStyles.less';

    .header {
        padding-top: 24px;
        padding-bottom: 24px;

        .container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    @media (max-width: 575px) {
        .footer {
            &-logo {
                margin: 0 auto 32px;
            }
        }
    }
</style>